<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      New Driver
    </hero-bar>
    <section class="section is-main-section">
      <form @submit.prevent="submit">
        <card-component title="Driver Details" icon="account">
          <div class="columns">
            <div class="column is-three-fifths">
              <b-field label="Driver Name" expanded>
                <b-input
                  minlength="3"
                  v-model="driver.name"
                  name="driverName"
                  required
                />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Username">
                <b-input
                  minlength="3"
                  v-model="driver.username"
                  name="driverUsername"
                  required
                />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Contact">
                <b-input
                  minlength="8"
                  v-model="driver.contact"
                  name="driverContact"
                  required
                />
              </b-field>
            </div>
          </div>
          <hr />
          <b-field grouped position="is-right">
            <div class="control">
              <b-button @click="exit"
                >Cancel</b-button
              >
            </div>
            <div class="control">
              <b-button native-type="submit" type="is-primary"
                >Submit</b-button
              >
            </div>
          </b-field>
        </card-component>
      </form>
    </section>
  </div>
</template>

<script>
import driver from '@/services/driver'

import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'

export default {
  name: 'CreateCustomer',
  components: {
    TitleBar,
    HeroBar,
    CardComponent
  },
  data () {
    return {
      driver: {}
    }
  },
  methods: {
    submit () {
      this.createDriver()
    },
    async createDriver () {
      try {
        await driver.createDriver(this.driver)
        this.$buefy.snackbar.open({
          message: 'Driver created successfully',
          queue: false
        })
        this.$router.go(-1)
      } catch (error) {
        this.$buefy.snackbar.open({
          message: error.message,
          queue: false
        })
      }
    },
    exit () {
      // check if there is data added
      if (Object.values(this.customer).length) {
        this.$buefy.dialog.confirm({
          message: 'Information will not be saved. Are you sure you want to exit?',
          onConfirm: () => {
            this.$router.go(-1)
          }
        })
      } else {
        this.$router.go(-1)
      }
    }
  },
  computed: {
    titleStack () {
      return ['Drivers', 'New']
    }
  }
}
</script>
